<template>
  <el-dialog
    :visible="dialogVisible"
    :close-on-click-modal="false"
    :show-close="true"
    width="25%"
    @close="closeDialog"
    class="basicLicenseExpireDialog"
  >
    <span slot="title">
      <div class="title" style="display: flex; align-items: center">
        <div
          style="
            font-size: 18px;
            font-weight: 700;
            padding-right: 28px;
            color: rgba(56, 56, 56, 1);
          "
        >
          {{ title_text }}
        </div>
      </div>
    </span>
    <div class="content">
      <div class="content-1">
        <i class="iconfont icon-alert_blue" style="font-size: 16px"></i
        ><span style="padding-left: 8px; text-align: left">
          {{
            $t("expire.expire_notice1") +
            basic_license_time +
            $t("expire.expire_notice2")
          }}</span
        >
      </div>
      <div class="content-2">
        <div><img src="@/assets/img/Erweicaihong.png" alt="" /></div>
        <div
          style="
            font-size: 12px;
            font-weight: 400;
            color: rgba(166, 166, 166, 1);
          "
        >
          {{ $t("expire.buy") }}
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "basicLicenseExpireDialog",
  props: {
    // 需传入三个参数
    // 1.弹窗是否显示
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    // 2.标题文字
    title_text: {
      type: String,
      default: "",
    },

    basic_license_time: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // dialogVisible: false,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("update:dialogVisible", false);
    },
  },
};
</script>

<style lang="scss">
.basicLicenseExpireDialog {
  .el-dialog__body {
    .content {
      .content-1 {
        border-radius: 4px;
        background: rgba(228, 235, 241, 0.62);
        font-size: 14px;
        font-weight: 400;
        padding: 10px 9px;
        color: rgba(56, 56, 56, 1);
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .content-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        padding-top: 19px;
        padding-bottom: 41px;
      }
      .content-3 {
        color: rgba(56, 56, 56, 1);
        font-size: 14px;
        font-weight: 400;
        padding: 26px 0;
      }
    }
  }
}
</style>
